import React from "react";

const LaptopIcon: React.FC = () => <>
  <svg width="128" height="49" viewBox="0 0 128 49" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M90.3666 44.819H94.3079L74.7996 0H71.1328L90.3666 44.819Z" fill="black"/>
  <path d="M20.6694 47.9167H127.967V42.4336H18.4766L20.6694 47.9167Z" fill="black"/>
  <path d="M20.6694 47.9167H127.967V42.4336H18.4766L20.6694 47.9167Z" fill="black"/>
  <path d="M20.5128 47.9181H91.6469L71.1341 0H0L20.5128 47.9181Z" fill="#275C61"/>
  <path d="M124.444 47.9193H4.77734" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M56.2707 26.1354C56.2707 21.6495 52.6212 18 48.1354 18C43.6495 18 40 21.6495 40 26.1354C40 30.6212 43.6495 34.2707 48.1354 34.2707C52.6212 34.2707 56.2707 30.6212 56.2707 26.1354ZM48.1354 32.7468C44.4898 32.7468 41.5239 29.7809 41.5239 26.1354C41.5239 22.4898 44.4898 19.5239 48.1354 19.5239C51.7809 19.5239 54.7468 22.4898 54.7468 26.1354C54.7468 29.7809 51.7809 32.7468 48.1354 32.7468Z" fill="white"/>
  <path d="M50.9618 23.3091C50.6642 23.0115 50.1818 23.0115 49.8843 23.3091L48.1374 25.056L46.3905 23.3091C46.0929 23.0115 45.6105 23.0115 45.313 23.3091C45.0155 23.6067 45.0155 24.0891 45.313 24.3866L47.0599 26.1335L45.313 27.8804C45.0155 28.178 45.0155 28.6604 45.313 28.9579C45.4618 29.1066 45.6568 29.1811 45.8518 29.1811C46.0467 29.1811 46.2418 29.1067 46.3905 28.9579L48.1374 27.2111L49.8843 28.958C50.033 29.1067 50.2281 29.1812 50.423 29.1812C50.618 29.1812 50.8131 29.1068 50.9618 28.958C51.2594 28.6604 51.2594 28.178 50.9618 27.8805L49.2149 26.1336L50.9618 24.3867C51.2594 24.0891 51.2594 23.6067 50.9618 23.3091Z" fill="white"/>
  </svg>
</>

export default LaptopIcon;
