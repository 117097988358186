import React from "react";
import { Link } from "gatsby";

import { buttonStyle } from "../components/styles";
import { Base, LimitMaxWidth } from "../components/layout"
import SEO from "../components/seo";

import LaptopIcon from "../assets/svg/icon-laptop";

export const Head = () => <SEO title="Syrona Health | 404" />

const Http404Page: React.FC = () => <Base><Http404Content/></Base>

export default Http404Page;

const Http404Content: React.FC = () => <>
  <div className="max-md:py-12 md:py-24">
    <LimitMaxWidth>
      <div className="flex flex-col items-center justify-center">
        <div className="text-h1 font-dm text-black">
          Error
        </div>
        <div className="text-150 font-nunsb text-black">
          404
        </div>
        <LaptopIcon />
        <div className="mt-8 text-h4 font-dm text-black">
          Oops! This page has gone missing.
        </div>
        <div className="text-b1 font-nun text-black">
          You can click the button to return to the Homepage
        </div>
        <div className="mt-8">
          <Link to="/">
            <button className={`${buttonStyle} bg-syr-snd text-white`}>
              Go Back to Home
            </button>
          </Link>
        </div>
      </div>
    </LimitMaxWidth>
  </div>
</>

